import styled from 'styled-components';
import overlayIllustration from 'assets/illustrations/overlay.svg';
import { Device } from '../../common';

export const Wrapper = styled.div`
  padding-bottom: 30rem;
  
  background-image: url(${overlayIllustration});
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  rect {
    fill: none;
  }
`;

export const IntroSlice = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 115px;
    ${'' /* z-index: 999; */}
`;

export const IntroContainer = styled.div`
  ${'' /* width: 100%; */}
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  


  @media (min-width: 576px) {
      max-width: 540px;
  }


  @media (min-width: 768px) {
      max-width: 720px;
  }
  @media (min-width: 992px) {
      max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;


export const IntroWrapper = styled.div`
  ${'' /* padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: 190px;
  width: 100%; */}
  @media (min-width: 992px) {
    justify-content: space-between!important;
    flex-direction: row!important;
  }

    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: flex-end!important;


    justify-content: flex-start!important;


    flex-direction: column!important;


  
`;

export const Details = styled.div`
  flex: 1;
  padding: 150px 150px 0 0px;
  align-self: flex-start!important;
  align-items: flex-start!important;

  @media (max-width: 960px) {
    ${'' /* width: 100%; */}
    ${'' /* margin-bottom: 2rem; */}
    padding: 10%;
  }
  p {
    font-size: 14pt;
    font-style: italic;
  }
  strong {
    color: #00A2E9;

    ${'' /* overflow: hidden; */}
    ${'' /* text-decoration: underline; */}
  }
  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
    text-transform: uppercase;
    letter-spacing: 2px;



    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
      ${'' /* word-break: break-word; */}
      ${'' /* word-spacing: 25vw; */}
      .loop {
        width: 100%;
      }
    }

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 32pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#707070' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  overflow: hidden;
  padding-bottom: 50px;
  
  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;
