import React, { useContext, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import Star from 'components/common/Icons/Star';
import Fork from 'components/common/Icons/Fork';
import { Wrapper, Grid, Item, Content, Stats, Languages } from './styles';
import cloudMigImg from '../../../assets/thumbnail/cloud_mig.svg';
import softwareEngImg from '../../../assets/thumbnail/development.svg';
import secImg from '../../../assets/thumbnail/protection.svg';
import devOpsImg from '../../../assets/thumbnail/startup.svg';
import managedServicesImg from '../../../assets/thumbnail/cloud_data.svg';
import cloudOptimizeImg from '../../../assets/thumbnail/cloud_opt.svg';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);
  const [isFlipped4, setIsFlipped4] = useState(false);
  const [isFlipped5, setIsFlipped5] = useState(false);
  const [isFlipped6, setIsFlipped6] = useState(false);

  const handleClick = (isFlip, setFlip) => {
    setFlip(!isFlip);
  }

  const services = [
    {
      id: 1,
      name: 'Cloud Managed Services',
      img: managedServicesImg,
      desc: 'Let us be responsible for your cloud. Monitoring, recovery, backups.. so you can focus on what you do best'
    },
    {
      id: 2,
      name: 'DevOps Consulting',
      img: devOpsImg,
      desc: ''
    },
    {
      id: 3,
      name: 'Cloud Optimization',
      img: cloudOptimizeImg,
      desc: ''
    },
    {
      id: 4,
      name: 'Cloud Migration Services',
      img: cloudMigImg,
      desc: 'We have been working in data centers since the ’90s and are capable of dealing with your on-premise mainframe, a collection of servers in a closet, a stack of pizza boxes racked in a co-lo, or a mix of all of the above.'
    },
    {
      id: 5,
      name: 'Security Audit',
      img:secImg,
      desc: ''
    },
    {
      id: 6,
      name: 'Software Engineering',
      img: softwareEngImg,
      desc: ''
    }
]


  return (
    <Wrapper as={Container} id="projects">
      <div className="services-raised-box">
      <h2>Services</h2>
      <p>Kumofy takes your hard-to-solve technology problems and delivers custom solutions to help your team exceed expectations. We help get things off the ground and continue supporting your efforts even after launch to ensure you grow, are able to scale and meet your business goals.</p>
      <Grid>
        {services.map(( service, index ) => {
          const isFlip = eval(`isFlipped${service.id}`);
          const setIsFlip = eval(`setIsFlipped${service.id}`);
          return (
            <div className="services--container">
            
            <ReactCardFlip isFlipped={isFlip} >
              <div className={`card-bg-color card-bg-color-${service.id}`} onClick={() => handleClick(isFlip, setIsFlip)}> 
                <h3>{service.name}</h3>
                
                <img className="service-img--front" alt="featured" src={service.img}/>  
              </div>

              <div className={`card-bg-color card-bg-color-${service.id}`} onClick={() => handleClick(isFlip, setIsFlip)}> 
              <img className="service-img--back" alt="featured" src={service.img}/>  
                <p>{service.desc}</p>
                
              </div>
          </ReactCardFlip>
            </div>
        
          )
        })}
      </Grid>
      </div>
    </Wrapper>
  );
};



            // {/* <Card theme={theme}>
            //   <Content>
            //     <h4>{service.name}</h4>
            //     <p>{service.desc}</p>
            //   </Content>
            //   <TitleWrap>
            //     <Stats theme={theme}>
            //       <div>
            //         <Star color={theme === "light" ? "#000" : "#fff"} />
            //         <span>{service.img}</span>
            //       </div>
            //       <div>
            //         <Fork color={theme === "light" ? "#000" : "#fff"} />
            //         <span>{service.forkCount}</span>
            //       </div>
            //     </Stats>
            //     <Stats theme={theme}> */}
            //       {/* <Languages>
            //         {
            //           service.languages.nodes.map(({ id, name }) => (
            //             <span key={id}>
            //               {name}
            //             </span>
            //           ))
            //         }
            //       </Languages> */}
            //     {/* </Stats>
            //   </TitleWrap>
            // </Card> */}