import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import TextLoop from "react-text-loop";
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/devops_trans.png';
import { Wrapper, IntroSlice, IntroWrapper, IntroContainer, Details, Thumbnail } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
<>
      <Header />
          <Wrapper>
      <IntroSlice>
        <IntroContainer>
          <IntroWrapper>
            <Details theme={theme}>
              <h1>Let's upgrade your&nbsp;
              <TextLoop interval={2000} className="loop">
                <strong>Cloud</strong>
                <strong>DevOps</strong>
                <strong>Security</strong>
                <strong>Workflow</strong>
                <strong>Stack</strong>
                <strong>Hosting</strong>
              </TextLoop>
              </h1>
              <p>We are a DevOps, software development and cloud construction team solving real problems in real ways.</p>
              {/* <Button as={AnchorLink} href="#contact">
                Lets Talk
              </Button> */}
            </Details>
            <Thumbnail>
            <img src={dev} alt="Kumofy dev" />
            {/* <Lottie options={defaultOptions}
            speed={.5}

        /> */}
        </Thumbnail>
            {/* <Thumbnail>
              <img src={dev} alt="I’m John and I’m a JAMStack engineer!" />
            </Thumbnail> */}
          </IntroWrapper>
        </IntroContainer>

      </IntroSlice>
      </Wrapper>
      </>

  );
};
