import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 150px;

  @media (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const ContactSlice = styled.div`
position: relative;
    display: flex;
    align-items: center;
    @media (max-width: 960px) {
      margin: 50px auto 0;
    }
    margin: 150px auto 0;
    width: 75%;
`;

export const Details = styled.div`
  flex: 1;
  padding-right: 2rem;

  @media (max-width: 960px) {
    padding-right: unset;
    width: 100%;
    order: 1;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: #212121;
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: #707070;
  }
  strong {
    color: #00A2E9;
    text-decoration: underline;
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    position: absolute;
    z-index: -1;
    ${'' /* top: 60vh; */}
    width: 100%;
    margin-bottom: -85vh;
  }

  img {
    width: 100%;
  }
`;
