import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/devops_named.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);
  const { height, width } = useWindowDimensions();
  
  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>

      {width >= 960 && (
        <Thumbnail>
          <img src={dev} alt="Kumofy dev" />
        </Thumbnail>
      )}

        <Details theme={theme}>
          <h1>The Kumofy Way</h1>
          <p>
            We have a difficult time with the marketing fluff, simply put we are a development team who just so happens to love the <strong>development cycle</strong> and every facet of <strong>cloud infrastructure.</strong> Over the years we noticed a ton of energy was being spent by us working through (and always improving) our <strong>best practices, DevOps flow, & cloud architecture</strong>. We discovered that's what drives us, gets us up in the morning, and keeps the fuel burning for those late nights. Even more than that, we <strong>love sharing</strong> what we've learned and continue to learn with other teams.
          </p>
          <Button as={AnchorLink} href="#contact">
            Lets Connect
          </Button>
        </Details>
        {width < 960 && (
        <Thumbnail>
          <img src={dev} alt="Kumofy dev" />
        </Thumbnail>
      )}
      </SkillsWrapper>
    </Wrapper>
  );
};
