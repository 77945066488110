import React, { useContext, useState } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import Star from 'components/common/Icons/Star';
import Fork from 'components/common/Icons/Fork';
import { Wrapper, Grid, Item, Content, Stats, Languages } from './styles';
import gitlabImg from '../../../assets/thumbnail/partners/gitlab.svg';
import awsImg from '../../../assets/thumbnail/partners/aws.png';
import cloudflareImg from '../../../assets/thumbnail/partners/cloudflare.svg';
import pingdomImg from '../../../assets/thumbnail/partners/pingdom.png';
import k8 from '../../../assets/thumbnail/partners/Kubernetes_logo.svg';
import docker from '../../../assets/thumbnail/partners/Docker_(container_engine)_logo.svg';

export const Partners = () => {
  const { theme } = useContext(ThemeContext);



  const partners = [
    {
      id: 1,
      name: 'Cloudflare',
      img: cloudflareImg,
      desc: 'Let us be responsible for your cloud. Monitoring, recovery, backups.. so you can focus on what you do best'
    },
    {
      id: 2,
      name: 'AWS',
      img: awsImg,
      desc: ''
    },
    {
      id: 3,
      name: 'Pingdom',
      img: pingdomImg,
      desc: ''
    },
    {
      id: 4,
      name: 'Docker',
      img: docker,
      desc: ''
    },
    {
      id: 5,
      name: 'k8',
      img: k8,
      desc: ''
    },
    {
      id: 6,
      name: 'GitLab',
      img: gitlabImg,
      desc: 'We have been working in data centers since the ’90s and are capable of dealing with your on-premise mainframe, a collection of servers in a closet, a stack of pizza boxes racked in a co-lo, or a mix of all of the above.'
    },
]


  return (
    <Wrapper as={Container} id="projects">
      <div className="partners-raised-box">
        <h2>What we use and love</h2>

        <Grid>
          {partners.map(( partner, index ) => (
            <img src={partner.img} alt={partner.name}></img>
            )
          )}
        </Grid>
      </div>
    </Wrapper>
  );
};



            // {/* <Card theme={theme}>
            //   <Content>
            //     <h4>{service.name}</h4>
            //     <p>{service.desc}</p>
            //   </Content>
            //   <TitleWrap>
            //     <Stats theme={theme}>
            //       <div>
            //         <Star color={theme === "light" ? "#000" : "#fff"} />
            //         <span>{service.img}</span>
            //       </div>
            //       <div>
            //         <Fork color={theme === "light" ? "#000" : "#fff"} />
            //         <span>{service.forkCount}</span>
            //       </div>
            //     </Stats>
            //     <Stats theme={theme}> */}
            //       {/* <Languages>
            //         {
            //           service.languages.nodes.map(({ id, name }) => (
            //             <span key={id}>
            //               {name}
            //             </span>
            //           ))
            //         }
            //       </Languages> */}
            //     {/* </Stats>
            //   </TitleWrap>
            // </Card> */}