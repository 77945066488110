import styled from 'styled-components';
import overlayIllustration from 'assets/illustrations/overlay.svg';

export const Wrapper = styled.div`
  border-radius: 40px;
  rect {
    fill: none;
  }
  padding: 2rem 0;
  position: relative;
  min-height: 500px;
  @media (max-width: 960px) {
    margin-top: 100px;
  }
  margin-top: 200px;



.service-img--front {
  width: 150px;
  vertical-align: middle;
  position: absolute;
    top: 75px;
}

.service-img--back {

  width: 90px;
    position: absolute;
    top: 0;
    left: 50%;
    padding: 20px;
    transform: translate(-50%,0);

}
.services-raised-box {
    position: relative;
    box-shadow: 3px -5px 20px rgb(0 0 0 / 16%);
    ${'' /* background-color: #fff; */}
    border-radius: 15px;
    padding: 50px;
    z-index: 999;
    background-image: url(${overlayIllustration});
    background-size: cover;
    border-radius: 40px;
    background-position: left top;
    background-repeat: no-repeat;
    ${'' /* width: 100%; */}
    @media(max-width:960px) {
      padding: 0;
      box-shadow: none;
      
    }

    h2 {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 30pt;
      ${'' /* line-height: 56px; */}
    }
    p {
      padding-bottom: 2.5rem;
    font-size: 16pt;
    font-weight: normal;
    line-height: 1.3;
    color: #707070;
    @media (max-width: 960px) {
      width: 95%;
    }
    width: 73%;
    text-align: center;
    margin: auto;
    position: relative;
    top: 30%;
    }
}
.services--container {
  cursor: pointer;
}
    .card-bg-color {
      display: flex;
      flex-direction: column;
      vertical-align: middle;
      height: 250px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: 0.2s;

      background-color: transparent !important;
      @media (min-width: 960px) {
        width: 85%;
        padding: 20px;
        
      }
      margin-bottom: 20px;

      &:hover {
        opacity: 0.5;
      }
    
    h3 {
      position: absolute;
      text-transform: uppercase;
      top: 25px;
      color: #000;
      font-size: 17pt;
      }
    }

    .card-bg-color-1 {
      background-color: #A389F4;
    }
    .card-bg-color-2 {
      background-color: #FCD55F;
    }
    .card-bg-color-3 {
      background-color: #FC6E6A;
    }
    .card-bg-color-4 {
      background-color: #2AEC7E;
    }
    .card-bg-color-5 {
      background-color: #FAA827;
    }
    .card-bg-color-6 {
      background-color: #85D7FF;
    }
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  ${'' /* width: 100%; */}
  height: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }

  p {
    color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};
  }
`;

export const Content = styled.div`
  padding: 1rem 0;
  min-height: 160px;
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    &:first-child {
      margin-right: 0.5rem;
    }

    img {
      margin: 0;
    }

    svg path {
      fill: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
    }

    span {
      color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
      margin-left: 0.5rem;
    }
  }
`;

export const Languages = styled.div`
  opacity: 0.5;
  font-size: 14px;
`;
