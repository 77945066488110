import React from 'react';
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import { Container } from 'components/common';
import contact from 'assets/illustrations/contact.svg';
import { Wrapper, Details, Thumbnail, ContactSlice } from './styles';
import ContactForm from './ContactForm';
import useWindowDimensions from 'hooks/useWindowDimensions';
// import test from 'assets/Animation02/drawkit-grape-animation-2-LOOP.json';
// import Lottie from 'react-lottie';


export const Contact = () => {
  const { height, width } = useWindowDimensions();
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: test,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // };
  return (
  <Wrapper id="contact">
    <ContactSlice>
    <Details>
    <p>Contact us for a <strong>FREE</strong> 30-minute consultation.</p>
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.GATSBY_PORTFOLIO_RECAPTCHA_KEY}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      <ContactForm />
    </GoogleReCaptchaProvider>
    </Details>
    {width >= 960 && (
    <Thumbnail>
            {/* <Lottie options={defaultOptions}
            speed={.5}

        /> */}
      <img src={contact} alt="I’m John and I’m a Backend & Devops engineer!" />
    </Thumbnail>
    )}
    </ContactSlice>
  </Wrapper>
  )
};
